import * as React from 'react'

import { Typography } from '@wicadu/arepa/ui'

import useTemplate from '@hooks/useTemplate'
import Layout from '@HOCs/Layout/Layout'
import { type ArticleSection } from '@ts-types/ArticleSection'
import { type TermsOfUseScreen } from '@ts-types/Pages/Legal/TermsOfUse'

function TermsOfUseTemplate() {
  const { translate, lang, sections } = useTemplate<TermsOfUseScreen>()

  return (
    <Layout>
      <Layout.PageGrid
        withBackArrow
        title={translate[lang].ARTICLE.TITLE as string}
        description={translate[lang].ARTICLE.DESCRIPTION as string}
      >
        {sections?.map(({ title, content }: ArticleSection) => (
          <Layout.Section title={title} key={title}>
            {content?.map((paragraph: string, index: number) => (
              <Typography
                key={index}
                type="description"
                lineHeight={24}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
          </Layout.Section>
        ))}
      </Layout.PageGrid>
    </Layout>
  )
}

export default TermsOfUseTemplate
